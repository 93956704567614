.wrapperText {
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
}

.key-wrapper {
    float: left;
    margin: 0 20px 0 0;
}

.key-id {
    font-weight: 400;
    color: white;
    text-align: center;
    height: 100%;
    position: relative;
    z-index: 10;
    font-size: 14px;
}

.svg-pin {
    position: relative;
    bottom: 23px;
    z-index: 5;
}

.svg-icon {
    position: relative;
    z-index: 5;
}

.svg-icon img {
    height: 40px;
}

.svg-pin img {
    height: 32px;
}

.svg-games img {
    height: 25px
}

.title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #333;
}

.content-wrapper {
}

.custom-content-wrapper {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin: 20px 0 0 0;
}

.category {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #333;
}

.drawerSubHead {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #333;
    margin: 10px 0 5px 0;
}

.link-icon {
    position: relative;
    top: 4px;
    left: 3px;
    font-size: 14px !important;
}
