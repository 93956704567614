@import '../../variables';

.Flier {
    width: 100%;
    height: 280px;
    background-color: white;
}

.Legend {
    display: block;
    padding: 8px 16px;
    font-family: 'Open Sans', sans-serif;
}

.legend-title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: $green;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.legend-item-wrapper {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    min-height: 22px;
    font-weight: 400;
}

.legend-item-text {
    position: relative;
    bottom: 3px;
}

.sub-section {
    padding: 8px 16px 0 16px;
}

.games-icon {
    font-size: 19px;
    color: $green;
}

.map-pin {
    height: 15px;
    display: inline-block;
    margin: auto;
}

.legend-box {
    padding: 4px 4px 4px 0;
    margin: 0 6px 0 0;
    width: 20px;
    display: inline-block;
    text-align: center;
}

.list-item-wrapper {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.list-item-text {
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.87);
}

.list-link {
    text-transform: none;
    text-decoration: none;
}

.stage {
    width: 100%;
    background-color: $red;
}

.beer-garden {
    width: 100%;
    background-color: $green;
}

.free-events {
    border-radius: 15px;
    width: 15px;
    background-color: $blue;
}

.sponsor {
    border-radius: 15px;
    width: 15px;
    background-color: $yellow;
}

.restaurant {
    border-radius: 15px;
    width: 15px;
    background-color: $teal;
}

.entrance-box-office {
    border-radius: 15px;
    width: 15px;
    background-color: $purple;
}

.link-icon {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87);
}