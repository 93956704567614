html { height:100%; }
body {
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    margin: 0;
    padding: 0;
}
