$blue: #161540;
$red: #F23A29;
$green: #03A688;
$yellow: #F2C12E;
$teal: #3270A6;
$purple: #935195;

@font-face {font-family: 'Gobold Bold Italic';
    src: url('https://static1.squarespace.com/static/56ec9968746fb97c330f612b/t/5a946ee0e2c483a55b393661/1519677152858/Gobold+Bold+Italic.ttf') format('truetype'); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

$font-gobold-bold: 'Gobold Bold Italic';
