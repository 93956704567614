@import 'variables';

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  text-align: center;
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
  width: 100%;
}

// Global material design overrides

.MuiTabs-indicator {
  background-color: $blue !important;
}

.MuiTab-textColorPrimary.Mui-selected{
  color: $blue !important;
}
  